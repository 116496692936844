//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import ServiceAnalysisCard from "./ServiceAnalysisCard.vue";
import PaymentCard from "./PaymentCard";
import { queryGeneticAnalysisOrderById } from "@debionetwork/polkadot-provider";
import { queryGeneticAnalysisByGeneticAnalysisTrackingId } from "@debionetwork/polkadot-provider";
export default {
  name: "SuccessPage",
  data: () => ({
    stepperItems: [{
      number: 1,
      title: "Select Genetic Data",
      active: false
    }, {
      number: 2,
      title: "Select Service & Analyst",
      active: false
    }, {
      number: 3,
      title: "Checkout and Payment",
      active: false
    }, {
      number: 4,
      title: "Summary/Instruction",
      active: true
    }],
    geneticOrderAnalysisDetail: null,
    serviceAnalysis: null,
    geneticData: null,
    service: null,
    orderStatus: null,
    geneticAnalysis: null,
    rejectTitle: null,
    rejectDesc: null,
    isRejected: false,
    isRegistered: false,
    isInProgress: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet
    })
  },
  components: {
    ServiceAnalysisCard,
    PaymentCard
  },
  async mounted() {
    await this.getAnalysisOrderDetail();
    if (this.orderStatus === "Cancelled") {
      this.stepperItems[3].title = "Cancelled";
    }
    if (this.orderStatus === "Paid") {
      this.getAnalysisStatus();
    }
  },
  methods: {
    async getAnalysisOrderDetail() {
      const analysisOrderId = this.$route.params.id;
      const analysisOrderDetail = await queryGeneticAnalysisOrderById(this.api, analysisOrderId);
      if (analysisOrderDetail) {
        this.orderStatus = analysisOrderDetail.status;
        this.geneticOrderAnalysisDetail = analysisOrderDetail;
        this.trackingId = this.geneticOrderAnalysisDetail.geneticAnalysisTrackingId;
      }
    },
    async getAnalysisStatus() {
      this.isRegistered = false;
      this.isRejected = false;
      this.isInProgress = false;
      this.geneticAnalysis = await queryGeneticAnalysisByGeneticAnalysisTrackingId(this.api, this.trackingId);
      if (this.geneticAnalysis.status === "Rejected") {
        this.stepperItems[3].title = "Rejected";
        this.isRejected = true;
        this.rejectTitle = this.geneticAnalysis.rejectedTitle;
        this.rejectDesc = this.geneticAnalysis.rejectedDescription;
      }
      if (this.geneticAnalysis.status === "Registered") {
        this.isRegistered = true;
      }
      if (this.geneticAnalysis.status === "InProgress") {
        this.isInProgress = true;
      }
    }
  }
};